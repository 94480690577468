<a routerLink="/home" class="logo animate__animated animate__fadeInUp">SAHARAT STUDIO</a>
<div (click)="toggleMenu()" [ngClass]="{'active': menuActive}" class="btn-hamburger">
    <div class="line-1"></div>
    <div class="line-2"></div>
    <div class="line-3"></div>
</div>

<div [ngClass]="{'active': menuActive}" class="menu-sec">
    <div>
        <div class="menu">
            <a style="animation-delay: 0.1s;" class="animate__animated animate__fadeInUp" routerLinkActive="active"
                routerLink="/home">HOME</a>
            <a style="animation-delay: 0.2s;" class="animate__animated animate__fadeInUp" routerLinkActive="active"
                routerLink="/about">ABOUT</a>
            <a style="animation-delay: 0.3s;" class="animate__animated animate__fadeInUp" routerLinkActive="active"
                routerLink="/works">WORKS</a>
        </div>
        <div style="animation-delay: 0.5s;" class="social animate__animated animate__fadeIn">
            <a href="">
                <i class="fab fa-facebook-square"></i>
            </a>
            <a href="">
                <i class="fab fa-line"></i>
            </a>
            <a href="">
                <i class="fab fa-instagram"></i>
            </a>
            <a href="">
                <i class="fab fa-gitlab"></i>
            </a>
        </div>
    </div>
</div>