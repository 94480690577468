<div class="home-sec">
    <div style="background-image: url('./assets/images/txt.png');" class="text-curve"></div>
    <div id="character" style="background-image: url('./assets/images/character.png'); animation-delay: 0.1s;"
        class="character animate__animated animate__fadeIn"></div>
    <div class="intro">
        <div style="animation-delay: 0.3s; font-weight: bold;" class="name animate__animated animate__fadeInUp">SAHARAT PHONPO</div>
        <div style="animation-delay: 0.5s;" class="career animate__animated animate__fadeInUp">FRONT END DEVELOPER -
            DIGITAL DESIGNER
        </div>
        <div>
            <button style="animation-delay: 0.7s;" routerLink="/about"
                class="btn-link animate__animated animate__fadeInUp">About me !
            </button>
        </div>
        <div class="contact">
            <a target="_blank" href="https://www.facebook.com/saharatphonpo" style="animation-delay: 0.9s;" class="animate__animated animate__fadeIn">
                <i class="fab fa-facebook-square"></i>
            </a>
            <a target="_blank" style="animation-delay: 1s;" class="animate__animated animate__fadeIn">
                <i class="fab fa-line"></i>
            </a>
            <a target="_blank" style="animation-delay: 1.2s;" class="animate__animated animate__fadeIn">
                <i class="fab fa-instagram"></i>
            </a>
            <a target="_blank" style="animation-delay: 1.4s;" class="animate__animated animate__fadeIn">
                <i class="fab fa-gitlab"></i>
            </a>
        </div>
    </div>
    <a routerLink="/works" class="btn-work">
        <i class="fas fa-th"></i> WORKS
    </a>
    <div class="licen">
        Copyright © 2020 Powered by Saharat studio
    </div>
</div>