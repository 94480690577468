<div [ngClass]="{'active': viewWorkActive}" #workPreview class="work-preview"></div>
<div class="work-content" [ngClass]="{'active': viewWorkActive}">
    <div class="work-name animate__animated animate__fadeInUp">{{workDetail.name}}</div>
    <div class="work-des animate__animated animate__fadeInUp">{{workDetail.des}}</div>
    <div style="text-align: center; margin-top: 50px;">
        <a (click)="selectedWork(0)" class="btn-link">Back</a>
        <a target="_blank" [href]="workDetail.url" class="btn-link">Visit Site</a>
    </div>
</div>
<div [ngClass]="{'active': viewWorkActive}" class="work-sec">
    <div class="work-container">
        <div style="margin-bottom: 30px; font-style: italic;">Selected works</div>
        <div>
            <a (click)="selectedWork(i)" (mouseenter)="viewWork(i)" *ngFor="let work of works; let i = index;"
                class="work-list animate__animated animate__fadeInUp">
                <div class="name">{{work.projectName}}</div>
                <div class="des">{{work.description}}</div>
            </a>
        </div>
    </div>
</div>