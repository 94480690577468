import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';
import AOS from 'aos';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit, AfterViewInit {

  @ViewChild('loadingBar', { static: false }) loadingBar: ElementRef;
  @ViewChild('nameIntro', { static: false }) nameIntro: ElementRef;
  @ViewChild('progress', { static: false }) progress: ElementRef;

  loadingStatus: boolean;

  constructor(
    private loadingService: LoadingService
  ) {
    this.loadingStatus = this.loadingService.loadingStatus;
  }

  ngOnInit(): void {
    document.getElementById('main-page').style.display = 'none';
  }

  ngAfterViewInit() {
    if (!this.loadingStatus) {
      this.loading();
    } else {
      this.pageStart();
    }
  }

  loading() {
    let progress = 0;
    const loading = setInterval(
      () => {
        progress += 1;
        this.progress.nativeElement.style.width = progress + '%';
        if (progress >= 100) {
          clearInterval(loading);
          this.loadingService.setStatus(true);
          setTimeout(() => {
            this.loadingBar.nativeElement.style.width = '0';
          }, 1000);
          setTimeout(() => {
            this.nameIntro.nativeElement.style.opacity = '0';
            this.nameIntro.nativeElement.style.transform = 'translateY(10px)';
          }, 2000);
          setTimeout(() => {
            this.pageStart();
          }, 2500);
        }
      }, 50
    );
  }

  pageStart() {
    document.getElementById('intro-sec').style.display = 'none';
    document.getElementById('main-page').style.display = 'block';
    AOS.init();
  }

}
