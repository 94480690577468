<div data-aos="fade-in" data-aos-duration="1000" class="about-bg"></div>
<img class="circle-txt" src="./assets/images/textcircle2.png" alt="">
<div class="about-container">
    <div class="about">
        <div data-aos="fade-in" data-aos-duration="1000" data-aos-delay="100" class="name">
            I'M SAHARAT PHONPO
        </div>
        <div data-aos="fade-left" data-aos-duration="1000" data-aos-delay="200" style="color: #6b6b6b">
            FRONT-END DEVELOPER / DIGITAL DESGINER / UI UX DESIGNER
        </div>
        <div class="line"></div>
        <div data-aos="fade-in" data-aos-duration="1000" data-aos-delay="300" class="my-background"
            style="margin-bottom: 20px;">
            I'm a half web-designer and half web developer, working as a Front End Developer with 4 years. I
            graduated from <span style="color: #b4a890;">"Phranakhon Rajabhat University"</span>, work at Thailand as a web
            developer and a web designer. I know coding HTML, CSS, PHP, jQuery, JavaScript and Angular.
        </div>
    </div>
    <div class="exp">
        <div data-aos="fade-in" data-aos-duration="1000" class="topic">EXPERIANCE</div>
        <div data-aos="fade-left" data-aos-duration="1000" data-aos-delay="200" style="color: #6b6b6b">
            Usability Visualization Experiance
        </div>
        <div class="line"></div>
        <div class="exp-sec">
            <div data-aos="fade-in" data-aos-duration="1000" data-aos-delay="200" class="exp-list">
                <div class="pre">2019 - PRESENT</div>
                <div class="at">ADVANCED INFO SERVICE (AIS)</div>
                <div class="pos">SENIOR FRONT END DEVELOPER <br> UX UI DESIGNER</div>
            </div>
            <div data-aos="fade-in" data-aos-duration="1000" data-aos-delay="300" class="exp-list">
                <div class="pre">2018 - 2019</div>
                <div class="at">CREDIA STUDIO</div>
                <div class="pos">FULL STACK DEVELOPER </div>
            </div>
            <div data-aos="fade-in" data-aos-duration="1000" data-aos-delay="400" class="exp-list">
                <div class="pre">2017 - 2018</div>
                <div class="at">CREDIA STUDIO</div>
                <div class="pos">FRONT END DEVELOPER </div>
            </div>
            <div data-aos="fade-in" data-aos-duration="1000" data-aos-delay="500" class="exp-list">
                <div class="pre">2016 - 2017</div>
                <div class="at">CREDIA STUDIO</div>
                <div class="pos">WEB DESIGNER </div>
            </div>
        </div>
    </div>
    <!-- <div class="brand">
        <div>A few brands I've worked with</div>
    </div> -->
    <div class="contact">
        <div data-aos="fade-in" data-aos-duration="1000" class="topic">CONTACT</div>
        <div data-aos="fade-left" data-aos-duration="1000" data-aos-delay="200" style="color: #6b6b6b">
            If you are thinking about hiring me or would like to discuss a project, get in touch with me at
        </div>
        <div class="line"></div>
        <div class="contact-sec">
            <div data-aos="fade-in" data-aos-duration="1000" class="email" style="margin-bottom: 20px;">
                saharatphonpo@gmail.com
            </div>
            <div data-aos="fade-in" data-aos-duration="1000" class="social">
                <a class="animate__animated animate__fadeIn">
                    <i class="fab fa-facebook-square"></i>
                </a>
                <a class="animate__animated animate__fadeIn">
                    <i class="fab fa-line"></i>
                </a>
                <a class="animate__animated animate__fadeIn">
                    <i class="fab fa-instagram"></i>
                </a>
                <a class="animate__animated animate__fadeIn">
                    <i class="fab fa-gitlab"></i>
                </a>
            </div>
        </div>
    </div>
    <div data-aos="fade-up" data-aos-duration="1000" style="margin-top: 100px; text-align: center;">
        <a class="btn-see-work" routerLink="/works">
            SEE WORKS <i style="margin-left: 10px;" class="fas fa-long-arrow-alt-right"></i>
        </a>
    </div>
</div>