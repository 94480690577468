import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { WorkModel } from './work.model';
@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss']
})
export class WorkComponent implements OnInit, AfterViewInit {

  @ViewChild('workPreview') workPreview: ElementRef;

  viewWorkActive: boolean;

  workDetail = {
    name: '',
    des: '',
    url: ''
  };

  works: WorkModel[] = [
    {
      projectName: 'PUSIRI 54',
      description: 'Website project Pusiri54',
      imageSrc: './assets/images/project10.jpg',
      fullDescriotion: `
        ภูสิริ ริชชี่ แอสเสท คือ ONE STOP SERVICE ของ PROPERTY INVESTMENT ในประเทศไทย
        เป็นบริษัทที่ปรึกษาและบริษัทพัฒนาการลงทุนอสังหาริมทรัพย์ ที่โฟกัสเรื่องการช่วยเหลือลูกค้า
        ที่มีความตั้งใจจริงในการลงทุนทำโรงงานและธุรกิจ ให้ทำได้อย่างชาญฉลาด (Smart) และราบรื่น (Smooth)
        ดูแลตั้งแต่ขั้นตอนแรก และดูแลคุณจนเริ่มต้นธุรกิจได้จริง
      `,
      url: 'https://www.pusiri-a.com'
    },
    {
      projectName: 'VISION NEX',
      description: 'Website project Vision Nex',
      imageSrc: './assets/images/project13.jpg',
      fullDescriotion: `
        Visionnex’s working process is integration of knowledge,
        creativity and expertise in various technologies.
        Techniques can be applied to create sophisticated and multipurpose work. Therefore,
        within Visionnex’s factory is fully equipped with tools, equipment, creative design process,
        and the staff of quality forming Visionnex full potential to create full-service material production
        line from designing, manufacturing to finishing.
      `,
      url: 'http://www.vision-nex.com'
    },
    {
      projectName: 'FOUR INTERIOR',
      description: 'Website project Four Interior',
      imageSrc: './assets/images/project5.jpg',
      fullDescriotion: `
        บริษัท โฟร์พัฒนา อินทีเรีย จำกัด ก่อตั้งมาตั้งแต่ พ.ศ. 2546 เพื่อรับงานออกแบบตกแต่งภายในให้กับลูกค้าของบริษัทรับสร้างบ้าน โฟร์พัฒนา และลูกค้าทั่วไป เกิดขึ้นจากความร่วมมือของบริษัทโฟร์พัฒนา
        โดย คุณปราโมทย์ ธีรกุล กับ คุณพิมพิกา ประคัลภวงส์ และ ใช้พื้นที่ ชั้น 3 ของอาคารโฟร์พัฒนาเป็นสำนักงานตั้งแต่นั้นมา จวบจนปัจจุบัน
      `,
      url: 'http://www.fourinterior.com'
    },
    {
      projectName: 'FOUR PREMIUM',
      description: 'Website project Four Premium',
      imageSrc: './assets/images/project6.jpg',
      fullDescriotion: `
        บริษัท โฟร์พัฒนา พรีเมี่ยม จำกัด ได้จดทะเบียนก่อตั้งบริษัทฯ เมื่อวันที่ 3 เมษายน 2558 ภายใต้การบริหารงาน
        โดย คุณโอภาส ปัทธวัน กรรมการผู้จัดการ ที่ได้เล็งเห็นความต้องการของกลุ่มลูกค้าที่ต้องการสร้างบ้านตามแบบ
        ตามสไตล์ของตนเอง โดยมีแบบที่ได้ดีไซน์มาแล้ว หรือให้บริษัทฯ ออกแบบให้ใหม่ เป็นการตอบโจทย์ความต้องการของกลุ่มลูกค้าระดับพรีเมี่ยม
        โดยอยู่ในเครือ โฟร์พัฒนากรุ๊ป ที่มีประสบการณ์ในการรับสร้างบ้านกว่า 3 ทศวรรษ
      `,
      url: 'http://fourpattanapremium.com'
    },
    {
      projectName: 'SUMPHANGOLD',
      description: 'Website project Sumphangold',
      imageSrc: './assets/images/project8.jpg',
      fullDescriotion: `
        ห้างทองสัมพันธ์ สาขาภูเก็ต และ สาขา พังงา จำหน่ายทองรูปพรรณจากเยาวราช รับออมทอง0%
      `,
      url: ''
    },
    {
      projectName: 'GAONUM',
      description: 'Website project Gaonum',
      imageSrc: './assets/images/project9.jpg',
      fullDescriotion: `
        จดทะเบียนนิติบุคคลเมื่อปี 2557 เพื่อดำเนินกิจการ ซื้อมา-ขายไป ( Trading Business )
        ในกลุ่มสินค้าโทรศัพท์ smart phone tablet อุปกรณ์มือถือ อุปกรณ์เสริมเกี่ยวกับมือถือทั้งหมดไม่ว่าจะเป็น
        power bank cable สายชาร์ทต่าง ๆ และ gadget innovation ภายใต้แบรนต์ Bey's เป็นแบรนด์ลิขสิทธิ์ ของบริษัท ๙ นำ อินเตอร์เทรด จำกัด )
        และแบรนด์ อื่นๆ หรือ รูปแบบอื่นๆ ตามความต้องการของลูกค้า ด้วยเงื่อนไขที่ดีที่สุด
      `,
      url: 'http://www.gaonum.co.th'
    }
  ];

  constructor() {
    // this.imagePreview = this.works[0].imageSrc;
    this.viewWorkActive = false;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.workPreview.nativeElement.style.backgroundImage = `url(${this.works[0].imageSrc})`;
  }

  viewWork(index) {
    this.workPreview.nativeElement.style.backgroundImage = `url(${this.works[index].imageSrc})`;
  }

  selectedWork(index) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.viewWorkActive = this.viewWorkActive ? false : true;
    this.workPreview.nativeElement.style.backgroundImage = `url(${this.works[index].imageSrc})`;
    this.workDetail.name = this.works[index].projectName;
    this.workDetail.des = this.works[index].fullDescriotion;
    this.workDetail.url = this.works[index].url;
  }

}
