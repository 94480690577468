import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  constructor() { }

  ngOnInit(): void {
    window.addEventListener('mousemove', this.mouseMove, false);
  }

  ngOnDestroy() {
    window.removeEventListener('mousemove', this.mouseMove, false);
  }

  mouseMove(e) {
    const charecter = document.getElementById('character');
    charecter.style.transform = `
      translateX(${(window.innerWidth / 2 - e.clientX) / 120}px)
      translateY(${(window.innerHeight / 2 - e.clientY) / 120}px)
    `;
  }

}
