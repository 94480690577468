import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  menuActive: boolean;

  constructor(private router: Router) {
    this.menuActive = false;
  }

  ngOnInit() {
  }

  toggleMenu() {
    this.menuActive = this.menuActive ? false : true;
  }

  goMenu(menu) {
    this.router.navigate([`${menu}`]);
  }

}
