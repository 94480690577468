import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeModule } from './modules/home/home.module';
import { AboutModule } from './modules/about/about.module';
import { WorkModule } from './modules/work/work.module';
import { MainComponent } from './components/main/main.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: 'home',
    component: MainComponent,
    loadChildren: () => import('./modules/home/home.module').then(m => HomeModule)
  },
  {
    path: 'about',
    component: MainComponent,
    loadChildren: () => import('./modules/about/about.module').then(m => AboutModule)
  },
  {
    path: 'works',
    component: MainComponent,
    loadChildren: () => import('./modules/work/work.module').then(m => WorkModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
