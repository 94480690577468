import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  loadingStatus: boolean;

  constructor() {
    this.loadingStatus = false;
  }

  setStatus(status) {
    this.loadingStatus = status;
  }

}
